<template>
  <div>
    <h4>
      {{ $t('ACADEMICO.FILTROS') }}
    </h4>
    <b-row>
      <b-col md="3">
        <input-date-only-btn
          ref="dataInicio"
          v-model="filtro.dataInicio"
          :label="$t('FINANCEIRO.PERIODO_INICIAL')"
          required
        />
      </b-col>

      <b-col md="3">
        <input-date-only-btn
          ref="dataFim"
          v-model="filtro.dataFim"
          :label="$t('FINANCEIRO.PERIODO_FINAL')"
          required
        />
      </b-col>

      <b-col cols="12" md="3">
        <input-checkbox
          class="botao-acao-filtro pt-2 mb-md-3 w-100"
          v-model="filtro.ocultarEncerramentoExercicio"
          type="boolean"
          :label="$t('CONTABIL.OCULTAR_ENCERRAMENTO')"
          :placeholder="$t('CONTABIL.OCULTAR_ENCERRAMENTO')"
        />
      </b-col>

      <b-col md="auto">
        <b-btn
          class="botao-acao-filtro mb-3 w-100"
          variant="primary"
          @click="filtrar"
          >{{ $t('GERAL.FILTRAR') }}
        </b-btn>
      </b-col>

      <b-col md="auto">
        <b-btn
          class="botao-acao-filtro mb-3 w-100"
          variant="secondary"
          @click="limpar"
        >
          {{ $t('GERAL.LIMPAR') }}
        </b-btn>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// Components:
import { InputDateOnlyBtn, InputCheckbox } from '@/components/inputs';

export default {
  name: 'FiltroBalancete',

  props: {
    form: { type: Object, default: Object },
  },

  components: {
    InputDateOnlyBtn,
    InputCheckbox,
  },
  data() {
    return {
      filtro: {
        dataInicio: '',
        dataFim: '',
        ocultarEncerramentoExercicio: false,
      },
    };
  },
  mounted() {
    this.refs();
  },
  watch: {
    filtro: {
      handler(value) {
        this.$emit('atualizar-filtro', value);
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    refs() {
      this.$emit('refs', this.$refs);
    },
    gerarRelatorio() {
      this.$emit('gerar-relatorio');
    },
    // FUNÇÕES FILTRO
    filtrar() {
      this.$emit('filtrar', this.filtro);
    },
    limpar() {
      this.filtro = {
        dataInicio: '',
        dataFim: '',
        ocultarEncerramentoExercicio: false,
      };
      this.$emit('limpar');
    },
  },
};
</script>
