<template>
  <div>
    <topbar
      :titulo="$t('CONTABIL.RELATORIO_BALANCETE')"
      :subtitulo="$t('CONTABIL.RELATORIO_BALANCETE_DESCRICAO')"
      :breadcrumbs="breadcrumbs"
    />

    <filtro-balancete
      @limpar="limpar"
      @filtrar="filtrar"
      @atualizar-filtro="atualizarFiltro"
      @refs="capturarRefs"
    />

    <b-row class="pl-2">
      <b-col md="auto">
        <b-btn
          variant="info"
          class="botao-acao-filtro mb-3 w-100"
          @click="gerarRelatorioBalancete"
          >{{ $t('GERAL.GERAR_RELATORIO') }}</b-btn
        >
      </b-col>
    </b-row>
    <tabela-balancete :itens="relatorioBalancete" />

    <paginacao v-model="paginacao" :total="paginacao.total" @input="filtrar" />
  </div>
</template>

<script>
//COMPONENTS
import Paginacao from '@/components/paginacao/Paginacao';
import FiltroBalancete from '@/views/contabil/relatorios/relatorio-balancete/components/FiltroBalancete';
import TabelaBalancete from '@/views/contabil/relatorios/relatorio-balancete/components/TabelaBalancete';
import Topbar from '@/layouts/full-layout/horizontal-sidebar/Topbar.vue';

//AUX & UTILS
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import pdf from '@/common/utils/pdf';
import helpers from '@/common/utils/helpers';

//SERVICES
import BalanceteService from '@/common/services/contabil/balancete.service';

export default {
  name: 'ContabilRelatorioBalancete',
  components: {
    Paginacao,
    FiltroBalancete,
    TabelaBalancete,
    Topbar,
  },
  data() {
    return {
      paginacao: {
        numeroDaPagina: 1,
        tamanhoDaPagina: 10,
        total: 0,
      },
      relatorioBalancete: [],
      breadcrumbs: [
        { titulo: this.$t('CONTABIL.CONTABIL') },
        { titulo: this.$t('CONTABIL.RELATORIOS') },
        { titulo: this.$t('CONTABIL.RELATORIO_BALANCETE') },
      ],
    };
  },
  methods: {
    capturarRefs(refs) {
      this.$refs = refs;
    },
    validarFormulario() {
      return helpers.validarFormulario(this.$refs);
    },
    atualizarFiltro(filtro) {
      this.filtro = filtro;
    },
    filtrar() {
      if (!this.validarFormulario()) return;

      this.$store.dispatch(START_LOADING);
      BalanceteService.obterBalancetePaginado(this.filtro, this.paginacao)
        .then(({ data }) => {
          this.relatorioBalancete = data.itens;
          this.paginacao.total = data.paginacao.totalDeElementos;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    gerarRelatorioBalancete() {
      this.$store.dispatch(START_LOADING);
      BalanceteService.gerarRelatorioBalancete(this.filtro)
        .then(({ data }) => {
          if (data.documento) {
            let bytes = pdf.base64ToArrayBuffer(data.documento);
            let blob = new Blob([bytes], {
              type: 'application/pdf',
            });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = data.nomeArquivo;
            link.click();
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    limpar() {
      this.paginacao = {
        numeroDaPagina: 1,
        tamanhoDaPagina: 10,
        total: 0,
      };
      this.filtro = {
        dataInicio: '',
        dataFim: '',
        ocultarEncerramentoExercicio: false,
      };
      this.relatorioBalancete = [];
    },
  },
};
</script>
